// Colors
$primary: #6f63f2;
$white: #ffffff;
$textColor: #8e8f91;
$gray-50: #fafafa;
$gray-100: #f5f5f5;

$primary-50: #e0e4ff;
$primary-100: #c7cdff;
$primary-200: #a4aafc;
$primary-300: #8181f7;
$primary-400: #6f63f2;
$primary-500: #5f47e6;
$primary-600: #5338c9;
$primary-700: #432fa3;
$primary-800: #3a2f82;
$primary-900: #111023;

$secondary-50: #ecfdf5;
$secondary-100: #d1fae5;
$secondary-200: #a7f3d0;
$secondary-300: #6ee7b7;
$secondary-400: #34d399;
$secondary-500: #10b981;
$secondary-600: #059669;
$secondary-700: #047857;
$secondary-800: #065f46;
$secondary-900: #0f2222;

$danger-50: #fff2f4;
$danger-100: #ffe3e7;
$danger-200: #ffccd3;
$danger-300: #ef624d;
$danger-400: #f77284;
$danger-500: #f0435a;
$danger-600: #db253e;
$danger-700: #ba1c31;
$danger-800: #8a1928;
$danger-900: #210f10;

$success: $secondary-400;
$danger: $danger-400;
$primary: $primary-400;

$secondary: $secondary-400;

$neutral-900: #131416;
$neutral-800: #17181b;
$neutral-700: #1d1f23;
$neutral-600: #3d3f46;
$neutral-500: #4F525B;
$neutral-400: #71717a;
$neutral-300: #a1a1aa;
$neutral-200: #d4d4d8;
$neutral-100: #f5f5f5;
$neutral-50: #fafafa;
$neutral-0: #ffffff;

$orange: #ff9861;

$yellow-50: #fffdef;
$yellow-100: #fff8c0;
$yellow-200: #fff8c0;
$yellow-300: #fff4a0;
$yellow-400: #ccbe4e;
$yellow-500: #ccbe4e;
$yellow-600: #ccbe4e;
$yellow-700: #998e3a;
$yellow-800: #665f27;
$yellow-900: #414205;
$yellow: #ffed61;

$fontFamily: "Poppins", sans-serif;

$gold-50: hsl(45, 100%, 98%);
$gold-400: hsl(49, 100%, 69%);
$gold-900: hsl(54, 45%, 34%);

$silver-50: hsl(0, 0%, 95%);
$silver-400: #c0c0c0;
$silver-900: hsl(0, 0%, 50%);

$bronze-50: hsl(30, 100%, 98%);
$bronze-400: hsl(27, 100%, 69%);
$bronze-900: hsl(24, 45%, 34%);

$colors-primary: (
  50: $primary-50,
  100: $primary-100,
  200: $primary-200,
  300: $primary-300,
  400: $primary-400,
  500: $primary-500,
  600: $primary-600,
  700: $primary-700,
  800: $primary-800,
  900: $primary-900,
);

$colors-success: (
  50: $secondary-50,
  100: $secondary-100,
  200: $secondary-200,
  300: $secondary-300,
  400: $secondary-400,
  500: $secondary-500,
  600: $secondary-600,
  700: $secondary-700,
  800: $secondary-800,
  900: $secondary-900,
);

$colors-danger: (
  50: $danger-50,
  100: $danger-100,
  200: $danger-200,
  300: $danger-300,
  400: $danger-400,
  500: $danger-500,
  600: $danger-600,
  700: $danger-700,
  800: $danger-800,
  900: $danger-900,
);
$colors-neutral: (
  0: $neutral-0,
  50: $neutral-50,
  100: $neutral-100,
  200: $neutral-200,
  300: $neutral-300,
  400: $neutral-400,
  500: $neutral-500,
  600: $neutral-600,
  700: $neutral-700,
  800: $neutral-800,
  900: $neutral-900,
);

$colors-yellow: (
  50: $yellow-50,
  100: $yellow-100,
  200: $yellow-200,
  300: $yellow-300,
  400: $yellow-400,
  500: $yellow-500,
  600: $yellow-600,
  700: $yellow-700,
  800: $yellow-800,
  900: $yellow-900,
);

@mixin color-modifiers($array: [], $attribute: "color") {
  @each $name, $hex in $array {
    &-#{$name} {
      #{$attribute}: $hex;
    }
  }
}
@mixin color-outline-modifiers($array: []) {
  @each $name, $hex in $array {
    &-#{$name} {
      box-shadow: inset 0 0 0 1px $hex;
    }
  }
}
@mixin color-outline-modifiers-2($array: []) {
  @each $name, $hex in $array {
    &-#{$name} {
      box-shadow: inset 0 0 0 2px $hex;
    }
  }
}

@mixin color-hover-modifiers($array: [], $attribute: "color") {
  @each $name, $hex in $array {
    &-#{$name} {
      &:hover {
        #{$attribute}: $hex !important;
      }
    }
  }
}


.text-primary {
  @include color-modifiers($array: $colors-primary);
}
.text-success {
  @include color-modifiers($array: $colors-success);
}
.text-danger {
  @include color-modifiers($array: $colors-danger);
}
.text-neutral {
  @include color-modifiers($array: $colors-neutral);
}
.text-warning {
  @include color-modifiers($array: $colors-yellow);
}
.bg-primary {
  @include color-modifiers(
    $array: $colors-primary,
    $attribute: "background-color"
  );
}
.bg-success {
  @include color-modifiers(
    $array: $colors-success,
    $attribute: "background-color"
  );
}
.bg-danger {
  @include color-modifiers(
    $array: $colors-danger,
    $attribute: "background-color"
  );
}
.bg-neutral {
  @include color-modifiers(
    $array: $colors-neutral,
    $attribute: "background-color"
  );
}
.bg-warning {
  @include color-modifiers(
    $array: $colors-yellow,
    $attribute: "background-color"
  );
}
.outline-primary {
  @include color-outline-modifiers($array: $colors-primary);
}
.outline-success {
  @include color-outline-modifiers($array: $colors-success);
}
.outline-danger {
  @include color-outline-modifiers($array: $colors-danger);
}
.outline-neutral {
  @include color-outline-modifiers($array: $colors-neutral);
}
.outline-warning {
  @include color-outline-modifiers($array: $colors-neutral);
}

.outline-primary-2 {
  @include color-outline-modifiers-2($array: $colors-primary);
}
.outline-success-2 {
  @include color-outline-modifiers-2($array: $colors-success);
}
.outline-danger-2 {
  @include color-outline-modifiers-2($array: $colors-danger);
}
.outline-neutral-2 {
  @include color-outline-modifiers-2($array: $colors-neutral);
}
.outline-warning-2 {
  @include color-outline-modifiers-2($array: $colors-neutral);
}

.hover-bg-neutral {
  @include color-hover-modifiers(
    $array: $colors-neutral,
    $attribute: "background-color"
  );
}
.hover-bg-primary {
  @include color-hover-modifiers(
    $array: $colors-primary,
    $attribute: "background-color"
  );
}
.hover-bg-success {
  @include color-hover-modifiers(
    $array: $colors-success,
    $attribute: "background-color"
  );
}
.hover-bg-danger {
  @include color-hover-modifiers(
    $array: $colors-danger,
    $attribute: "background-color"
  );
}
.hover-bg-warning {
  @include color-hover-modifiers(
    $array: $colors-yellow,
    $attribute: "background-color"
  );
}

.outline-bronze {
  box-shadow: inset 0 0 0 2px $bronze-400;
}
.outline-silver {
  box-shadow: inset 0 0 0 2px $silver-400 !important;
}
.outline-gold {
  box-shadow: inset 0 0 0 2px $gold-400 !important;
}

.bronze {
  color: $bronze-400 !important;
}
.silver {
  color: $silver-400 !important;
}
.gold {
  color: $gold-400 !important;
}

.bg-over-raised {
  background-color: $neutral-50 !important;
}

.bg-primary-gradient {
  background: linear-gradient(to right, var(--primary-1), var(--secondary-1));
}
.bg-primary-gradient-light {
  background: linear-gradient(to right, var(--primary-1), var(--secondary-1));
}

.shadow-primary-light {
  box-shadow: 0 1px 20px rgba(199, 205, 255, 0.3);
}

.white {
  color: white;
}
.black {
  color: black;
}

.bg-black {
  background-color: #000000 !important;
}
.transparent {
  color: rgba(0, 0, 0, 0);
}

.bg-gradient-legendary {
  background: linear-gradient(to right, #f97316, #eab308);
}
.bg-gradient-fire-sale {
  background: linear-gradient(to right, #f97316, #eab308);
}
.bg-gradient-phantasia {
  background: linear-gradient(to right, #4f46e5, #6788f9);
}
.bg-gradient-packers {
  background: linear-gradient(to right, #46a78d, #ffb612);
}
.bg-gradient-primary {
  background: linear-gradient(to right, var(--primary-1), var(--secondary-1));
}
.bg-gradient-gold {
  background: linear-gradient(to right, #eab308, #fb923c);
}
.bg-gradient-boost {
  background: linear-gradient(to right, #ecc440, #ddac17);
}
.bg-gradient-danger {
  background: linear-gradient(to right, var(--danger-1), #f54076);
}
.bg-gradient-warning {
  background: linear-gradient(to right, var(--warning-1), #FFD400);
}

.bg-gradient-success {
  background: linear-gradient(to right, var(--success-1), #13a05f);
}

.bg-gradient-base {
  background: linear-gradient(to right, var(--base-2), var(--base-3));
}

.bg-dark-opaque {
  background: rgba(0, 0, 0, 0.7);
}
.bg-transparent {
  background-color: #00000000;
}

.disabled {
  cursor: not-allowed !important;
  box-shadow: none !important;
  pointer-events: none;
  opacity: 0.4;
}

