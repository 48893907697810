/**
    Display
    Overflow
    Position
    Top/Right/Bottom/Left
 */

@import './mixins';
@import './values';

// OVERFLOW

.overflow-auto {
  overflow: auto
}

.overflow-hidden {
  overflow: hidden
}

.overflow-clip {
  overflow: clip
}

.overflow-visible {
  overflow: visible
}

.overflow-scroll {
  overflow: scroll;
  scroll-behavior: smooth;
}

.overflow-x-auto {
  overflow-x: auto
}

.overflow-y-auto {
  overflow-y: auto
}

.overflow-x-hidden {
  overflow-x: hidden
}

.overflow-y-hidden {
  overflow-y: hidden
}

.overflow-x-clip {
  overflow-x: clip
}

.overflow-y-clip {
  overflow-y: clip
}

.overflow-x-visible {
  overflow-x: visible
}

.overflow-y-visible {
  overflow-y: visible
}

.overflow-x-scroll {
  overflow-x: scroll
}

.overflow-y-scroll {
  overflow-y: scroll
}

// POSITION

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.sticky {
  position: sticky
}

// DISPLAY

.hidden {
  display: none;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}



.hover\:flex:hover {
  display: flex;
}
.hover\:hidden:hover {
  display: none;
}


// VISIBILITY

.visible {
  visibility: visible
}

.invisible {
  visibility: hidden
}

.collapse {
  visibility: collapse
}


// TOP/BOTTOM, ETC...

.t {
  @include modifier($map: $remValues, $attribute: 'top')
}

.t- {
  @include modifier($map: $negativeRemValues, $attribute: 'top')
}

.b {
  @include modifier($map: $remValues, $attribute: 'bottom')
}

.b- {
  @include modifier($map: $negativeRemValues, $attribute: 'bottom')
}

.l {
  @include modifier($map: $remValues, $attribute: 'left')
}

.l- {
  @include modifier($map: $negativeRemValues, $attribute: 'left')
}

.r {
  @include modifier($map: $remValues, $attribute: 'right')
}

.r- {
  @include modifier($map: $negativeRemValues, $attribute: 'right')
}

.absolute-center {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.absolute-middle-vertical {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
}
.absolute-middle-horizontal {
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
}

.flex-1 {
  flex: 1 1 0
}

.flex-auto {
  flex: 1 1 auto
}

.flex-initial {
  flex: 0 1 auto
}

.flex-none {
  flex: none
}

.flex-grow {
  flex-grow: 1
}

.flex-grow-0 {
  flex-grow: 0
}

.flex-shrink {
  flex-shrink: 1
}

.flex-shrink-0 {
  flex-shrink: 0
}

.flex-0 {
  flex: 0 0 auto;
}



.flex {
  @include modifier($map: $percentValues, $attribute: 'flex-basis')
}

.gap {
  @include modifier($map: $remValues, $attribute: 'gap')
}


@for $i from 0 through 15 {
  .grid-cols-#{$i} {
    grid-template-columns: repeat(#{$i}, minmax(0, 1fr));
  }
  .col-span-#{$i} {
    grid-column: span #{$i} / span #{$i}
  }
  .z-#{$i} {
    z-index: #{$i}
  }

  @media (min-width: 640px) {
    .sm\:grid-cols-#{$i} {
      grid-template-columns: repeat(#{$i}, minmax(0, 1fr));
    }
    .sm\:col-span-#{$i} {
      grid-column: span #{$i} / span #{$i}
    }
  }
  @media (min-width: 768px) {
    .md\:grid-cols-#{$i} {
      grid-template-columns: repeat(#{$i}, minmax(0, 1fr));
    }
    .md\:col-span-#{$i} {
      grid-column: span #{$i} / span #{$i}
    }
  }
  @media (min-width: 1024px) {
    .lg\:grid-cols-#{$i} {
      grid-template-columns: repeat(#{$i}, minmax(0, 1fr));
    }
    .lg\:col-span-#{$i} {
      grid-column: span #{$i} / span #{$i}
    }
  }
  @media (min-width: 1280px) {
    .xl\:grid-cols-#{$i} {
      grid-template-columns: repeat(#{$i}, minmax(0, 1fr));
    }
    .xl\:col-span-#{$i} {
      grid-column: span #{$i} / span #{$i}
    }
  }
}

.flex-row {
  flex-direction: row;
  display: flex;
}

.flex-row-reverse {
  flex-direction: row-reverse;
  display: flex;
}

.flex-col {
  flex-direction: column;
  display: flex;
}

.flex-col-reverse {
  flex-direction: column-reverse;
  display: flex;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}

.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.items-center {
  align-items: center;
}
.items-baseline {
  align-items: baseline;
}
.items-stretch {
  align-items: stretch;
}

.self-auto {
  align-self: auto;
}
.self-start {
  align-self: flex-start;
}
.self-end {
  align-self: flex-end;
}
.self-center {
  align-self: center;
}
.self-stretch {
  align-self: stretch;
}
.self-baseline {
  align-self: baseline;
}

@media (min-width: 640px) {
  .sm\:flex-row {
    flex-direction: row;
    display: flex;
  }

  .sm\:flex-row-reverse {
    flex-direction: row-reverse;
    display: flex;
  }

  .sm\:flex-col {
    flex-direction: column;
    display: flex;
  }

  .sm\:flex-col-reverse {
    flex-direction: column-reverse;
    display: flex;
  }

  .sm\:flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sm\:justify-start {
    justify-content: flex-start;
  }
  .sm\:justify-end {
    justify-content: flex-end;
  }
  .sm\:justify-center {
    justify-content: center;
  }
  .sm\:justify-between {
    justify-content: space-between;
  }
  .sm\:justify-around {
    justify-content: space-around;
  }
  .sm\:justify-evenly {
    justify-content: space-evenly;
  }

  .sm\:items-start {
    align-items: flex-start;
  }
  .sm\:items-end {
    align-items: flex-end;
  }
  .sm\:items-center {
    align-items: center;
  }
  .sm\:items-baseline {
    align-items: baseline;
  }
  .sm\:items-stretch {
    align-items: stretch;
  }

  .sm\:self-auto {
    align-self: auto;
  }
  .sm\:self-start {
    align-self: flex-start;
  }
  .sm\:self-end {
    align-self: flex-end;
  }
  .sm\:self-center {
    align-self: center;
  }
  .sm\:self-stretch {
    align-self: stretch;
  }
  .sm\:self-baseline {
    align-self: baseline;
  }
}
@media (min-width: 768px) {
  .md\:flex-row {
    flex-direction: row;
    display: flex;
  }

  .md\:flex-row-reverse {
    flex-direction: row-reverse;
    display: flex;
  }

  .md\:flex-col {
    flex-direction: column;
    display: flex;
  }

  .md\:flex-col-reverse {
    flex-direction: column-reverse;
    display: flex;
  }

  .md\:flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .md\:justify-start {
    justify-content: flex-start;
  }
  .md\:justify-end {
    justify-content: flex-end;
  }
  .md\:justify-center {
    justify-content: center;
  }
  .md\:justify-between {
    justify-content: space-between;
  }
  .md\:justify-around {
    justify-content: space-around;
  }
  .md\:justify-evenly {
    justify-content: space-evenly;
  }

  .md\:items-start {
    align-items: flex-start;
  }
  .md\:items-end {
    align-items: flex-end;
  }
  .md\:items-center {
    align-items: center;
  }
  .md\:items-baseline {
    align-items: baseline;
  }
  .md\:items-stretch {
    align-items: stretch;
  }

  .md\:self-auto {
    align-self: auto;
  }
  .md\:self-start {
    align-self: flex-start;
  }
  .md\:self-end {
    align-self: flex-end;
  }
  .md\:self-center {
    align-self: center;
  }
  .md\:self-stretch {
    align-self: stretch;
  }
  .md\:self-baseline {
    align-self: baseline;
  }
}
@media (min-width: 1024px) {
  .lg\:flex-row {
    flex-direction: row;
    display: flex;
  }

  .lg\:flex-row-reverse {
    flex-direction: row-reverse;
    display: flex;
  }

  .lg\:flex-col {
    flex-direction: column;
    display: flex;
  }

  .lg\:flex-col-reverse {
    flex-direction: column-reverse;
    display: flex;
  }

  .lg\:flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .lg\:justify-start {
    justify-content: flex-start;
  }
  .lg\:justify-end {
    justify-content: flex-end;
  }
  .lg\:justify-center {
    justify-content: center;
  }
  .lg\:justify-between {
    justify-content: space-between;
  }
  .lg\:justify-around {
    justify-content: space-around;
  }
  .lg\:justify-evenly {
    justify-content: space-evenly;
  }

  .lg\:items-start {
    align-items: flex-start;
  }
  .lg\:items-end {
    align-items: flex-end;
  }
  .lg\:items-center {
    align-items: center;
  }
  .lg\:items-baseline {
    align-items: baseline;
  }
  .lg\:items-stretch {
    align-items: stretch;
  }

  .lg\:self-auto {
    align-self: auto;
  }
  .lg\:self-start {
    align-self: flex-start;
  }
  .lg\:self-end {
    align-self: flex-end;
  }
  .lg\:self-center {
    align-self: center;
  }
  .lg\:self-stretch {
    align-self: stretch;
  }
  .lg\:self-baseline {
    align-self: baseline;
  }
}

@media (min-width: 1280px) {
  .xl\:flex-row {
    flex-direction: row;
    display: flex;
  }

  .xl\:flex-row-reverse {
    flex-direction: row-reverse;
    display: flex;
  }

  .xl\:flex-col {
    flex-direction: column;
    display: flex;
  }

  .xl\:flex-col-reverse {
    flex-direction: column-reverse;
    display: flex;
  }

  .xl\:flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .xl\:justify-start {
    justify-content: flex-start;
  }
  .xl\:justify-end {
    justify-content: flex-end;
  }
  .xl\:justify-center {
    justify-content: center;
  }
  .xl\:justify-between {
    justify-content: space-between;
  }
  .xl\:justify-around {
    justify-content: space-around;
  }
  .xl\:justify-evenly {
    justify-content: space-evenly;
  }

  .xl\:items-start {
    align-items: flex-start;
  }
  .xl\:items-end {
    align-items: flex-end;
  }
  .xl\:items-center {
    align-items: center;
  }
  .xl\:items-baseline {
    align-items: baseline;
  }
  .xl\:items-stretch {
    align-items: stretch;
  }

  .xl\:self-auto {
    align-self: auto;
  }
  .xl\:self-start {
    align-self: flex-start;
  }
  .xl\:self-end {
    align-self: flex-end;
  }
  .xl\:self-center {
    align-self: center;
  }
  .xl\:self-stretch {
    align-self: stretch;
  }
  .xl\:self-baseline {
    align-self: baseline;
  }
}


