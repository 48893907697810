@import "./base.scss";

* {
  font-family: $fontFamily;
}
input[type="file"] {
  display: none;
}
input[type="tel"] {
  background-color: var(--base-2);
  border-radius: 8px;
  color: var(--neutral-2);
  border: none;
  padding: 12px;
  width: 100%;
  position: relative;
  transition: all 0.2s;
  margin-left: 12px;
}
input[type="date"] {
  background-color: var(--base-2);
  color: var(--neutral-2) !important;
}

.input {
  border-radius: 8px;
  border: none;
  padding: 12px;
  width: 100%;
  position: relative;
  transition: all 0.2s;
}

.input-with-icon {
  padding-left: 48px;
}

.input:focus {
  outline: none;
  box-shadow: 0px 0px 0px 2px var(--primary-1);
}
input[type="tel"]:focus {
  outline: none;
  box-shadow: 0px 0px 0px 2px var(--primary-1);
}

.input-container {
  position: relative;
  width: 100%;
  i {
    position: absolute;
    left: 12px;
    top: 0;
    bottom: 0;
    z-index: 2;
    font-size: 24px;
    color: $textColor;
    display: flex;
    align-items: center;
  }
}




.switch {
  position: relative;
  display: flex;
  width: 48px;
  min-width: 48px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-slider {
  position: absolute;
  cursor: pointer;
  border-radius: 12px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--base-3);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch-slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  border-radius: 50%;
  background-color: var(--neutral-2);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input[type="checkbox"]:checked + .switch-slider {
  background-color: var(--primary-1);
}

input:checked + .switch-slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}

