@import './mixins';
@import './values';

.shadow-1 {
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.shadow-2 {
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
}

.shadow-3 {
  box-shadow: 0 22px 36px rgb(0, 0, 0, 0.10);
}

.shadow-x {
  box-shadow: 32px 0 20px -20px rgba(#000, 0.08);
}

.shadow-none {
  box-shadow: 0 0 #0000;
}

@for $i from 0 through 100 {
  .opacity-#{$i} {
    opacity: calc(#{$i} / 100);
  }
  .hover\:opacity-#{$i}:hover {
    opacity: calc(#{$i} / 100);
  }
}

@each $key, $value in $remValues {
  .translate-x-#{$key} {
    transform: translateX(#{$value});
  }
  .translate-y-#{$key} {
    transform: translateY(#{$value});
  }
  .hover\:translate-x-#{$key}:hover {
    transform: translateX(#{$value});
  }
  .hover\:translate-y-#{$key}:hover {
    transform: translateY(#{$value});
  }
}

.scale-0 {
  transform: scale(0);
}

.scale-50 {
  transform: scale(.5);
}

.scale-75 {
  transform: scale(.75);
}

.scale-90 {
  transform: scale(.9);
}

.scale-95 {
  transform: scale(.95);
}

.scale-100 {
  transform: scale(1);
}

.scale-105 {
  transform: scale(1.05);
}
.hover\:scale-105:hover {
  transform: scale(1.05);
}

.scale-110 {
  transform: scale(1.1);
}

.scale-125 {
  transform: scale(1.25);
}

.scale-150 {
  transform: scale(1.5);
}

.transition {
  transition: all 0.2s;
}

.transition-long {
  transition: all 0.5s;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.pointer-events-none {
  pointer-events: none;
}

.contain {
  object-fit: contain;
}

.cover {
  object-fit: cover;
}








