@import "./Colors.scss";

.animation-bounce-infinite {
  animation-name: bounce-1;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
.animation-bounce-infinite-2 {
  animation-name: bounce-2;
  animation-duration: 4s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
.animation-bounce-infinite-3 {
  animation-name: bounce-3;
  animation-duration: 4s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
.animation-reveal {
  animation-name: fade-reveal;
  animation-duration: 1s;
  animation-timing-function: ease-out;
}
.animation-from-bottom {
  animation: fadeInUp 0.5s cubic-bezier(0.15, 0.25, 0.25, 1) 0s forwards;
  opacity: 0;
  // animation-name: from-bottom;
  // animation-duration: 0.5s;
  // animation-timing-function: cubic-bezier(0.15, 0.25, 0.25, 1);
}
.animation-from-left {
  animation: fadeInRight 0.5s cubic-bezier(0, 0, 0.25, 1) 0s forwards;
  opacity: 0;
}
.animation-from-left {
  animation: fadeInRight 0.5s cubic-bezier(0.15, 0.25, 0.25, 1) 0s forwards;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.15, 0.25, 0.25, 1);
}
.animation-from-top {
  animation: fadeInDown 0.5s cubic-bezier(0.15, 0.25, 0.25, 1) 0s forwards;
  opacity: 0;
}
.animation-from-right {
  animation: fadeInLeft 0.5s cubic-bezier(0.15, 0.25, 0.25, 1) 0s forwards;
  opacity: 0;
}
.animation-leave-bottom {
  animation-name: leave-bottom;
  animation-duration: 0.5s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}
.animation-leave-left {
  animation-name: leave-left;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.animation-leave-top {
  animation-name: leave-top;
  animation-duration: 0.5s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}
.animation-leave-right {
  animation-name: leave-right;
  animation-duration: 0.5s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}
.animation-fade {
  animation-name: fade;
  animation-duration: 1s;
  animation-timing-function: ease-out;
}
.animation-fade-half {
  animation-name: fade-half;
  animation-duration: 1s;
  animation-timing-function: ease-out;
}
.animation-flash {
  animation-name: flash;
  animation-duration: 1.4s;
  animation-iteration-count: 3;
  animation-timing-function: cubic-bezier(0.15, 0.25, 0.25, 1) 0s forwards;
}
.animation-flash-glow {
  animation-name: flash-glow;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}
.animation-load-skeleton {
  animation-name: load-skeleton;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.animation-enter-screen {
  animation-name: enter-screen;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
}
.animation-leave-screen {
  animation-name: leave-screen;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
}

.delay-1 {
  animation-delay: 0.5s !important;
}
.delay-2 {
  animation-delay: 0.6s !important;
}
.delay-3 {
  animation-delay: 0.7s !important;
}
.delay-4 {
  animation-delay: 0.7s !important;
}
.delay-5 {
  animation-delay: 0.9s !important;
}
.delay-6 {
  animation-delay: 1s !important;
}
.delay-7 {
  animation-delay: 1.1s !important;
}
.delay-8 {
  animation-delay: 1.2s !important;
}
.delay-9 {
  animation-delay: 1.3s !important;
}
.delay-10 {
  animation-delay: 1.4s !important;
}
.delay-1s {
  animation-delay: 1s !important;
}
.delay-2s {
  animation-delay: 2s !important;
}
.delay-3s {
  animation-delay: 3s !important;
}

.animation-translate-right {
  animation: slide-carousel 1s cubic-bezier(0,0,.24, 1);
}
.animation-translate-left {
  animation: slide-carousel-back 1s cubic-bezier(0,0,.24, 1);
}

@keyframes slide-carousel {
  from {
    transform: translateX(0%)
  }
  to {
    transform: translateX(-100%)
  }
}
@keyframes slide-carousel-back {
  from {
    transform: translateX(-100%)
  }
  to {
    transform: translateX(0%)
  }
}

@keyframes fade-reveal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-20px, 0px, 0);
    transform: translate3d(-20px, 0px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(20px, 0px, 0);
    transform: translate3d(20px, 0px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0px, -20px, 0);
    transform: translate3d(0px, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes enter-screen {
  from {
    bottom: -64px;
  }
  to {
    bottom: 48px;
  }
}
@keyframes leave-screen {
  0% {
    bottom: 48px;
    opacity: 1;
  }
  50% {
    bottom: 48px;
  }
  100% {
    bottom: -64px;
    opacity: 0;
  }
}

@keyframes from-bottom {
  0% {
    transform: translateY(40px);
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes from-left {
  from {
    transform: translateX(-100px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}
@keyframes from-top {
  from {
    transform: translateY(-100px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes from-right {
  from {
    transform: translateX(100px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes leave-bottom {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(500px);
  }
}
@keyframes leave-left {
  from {
    transform: translateX(0px);
    opacity: 1;
  }
  to {
    transform: translateX(-500px);
    opacity: 0;
  }
}
@keyframes leave-top {
  from {
    transform: translateY(0px);
    opacity: 1;
  }
  to {
    transform: translateY(-500px);
    opacity: 0;
  }
}
@keyframes leave-right {
  from {
    transform: translateX(0px);
    opacity: 1;
  }
  to {
    transform: translateX(500px);
    opacity: 0;
  }
}
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-half {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}
@keyframes flash {
  0% {
  }
  50% {
    opacity: 0;
    background-color: $neutral-500;
  }
  100% {
  }
}
@keyframes load-skeleton {
  from {
    right: 200%;
  }
  to {
    right: -100%;
  }
}

@keyframes flash-glow {
  0% {
    opacity: 0;
    text-shadow: 2px 2px 20px $success;
  }
  20% {
    opacity: 1;
    text-shadow: 2px 2px 20px $success;
  }
  40% {
    opacity: 0;
  }
  60% {
    opacity: 1;
    text-shadow: 2px 2px 20px $success;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce-1 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-12px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes bounce-2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes bounce-3 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.animation-moving-gradient {
  background-size: 200% 100%;
  background-position: center;
  -webkit-animation: moving-gradient-primary 1s
    cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-animation: moving-gradient-primary 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  animation: moving-gradient-primary 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.animation-moving-stroke {
  stroke: #5f47e6;
  -webkit-animation: moving-gradient-stroke 1s
    cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-animation: moving-gradient-primary 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  animation: moving-gradient-primary 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}
@keyframes moving-gradient-primary {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 51%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes moving-gradient-primary {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 51%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-webkit-keyframes moving-gradient-primary {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 51%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes moving-gradient-stroke {
  0% {
    stroke: #5f47e6;
  }

  100% {
    stroke: #6186ff;
  }
}

@keyframes indeterminateAnimation {
  0% {
    transform: translateX(0) scaleX(0);
  }
  40% {
    transform: translateX(0) scaleX(0.4);
  }
  100% {
    transform: translateX(100%) scaleX(0.5);
  }
}
.indeterminate-progress-bar {
  height: 4px;
  background-color: $secondary-900;
  width: 100%;
  overflow: hidden;
}

.indeterminate-progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: $success;
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

.animation-grow-y {
  animation-name: animation-grow-y;
  animation-duration: 0.5s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.animation-expand-y {
  animation-name: animation-expand-y;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

@keyframes animation-grow-y {
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 150px;
    opacity: 1;
  }
}

@keyframes animation-expand-y {
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 1000px;
    opacity: 1;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.shine-border {
  position: absolute;
  display: block;
  top: 0;
  left: "-100%";
  z-index: -9;
  height: 200%;
  width: 200%;
  // transform: rotate(-45deg);
  overflow: hidden;
  background: linear-gradient(
    to right,
    $primary-400 0%,
    $primary-200 25%,
    #fff 50%,
    $primary-200 75%,
    $primary-400 100%
  );
  background-size: 200% auto;

  animation: shine 3s linear infinite;
}
.animation-ping{
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1);
}

@keyframes ping {
  75%, 100% {
    transform: scale(1.1);
    opacity: 0;
  }
}
.animation-spin {
  animation: spin 1s linear infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animation-press {
  animation: press .5s cubic-bezier(0,0,.24,1);
}
@keyframes press {
  0% {
    transform: scale(1) translateY(0);
  }
  50% {
    transform: scale(.995) translateY(1px);

  }
  100% {
    transform: scale(1) translateY(0);
  }
}

/*Begin shimmer code*/

@keyframes shine {
  to {
    background-position: 200% center;
  }
}

.linear-shine-packers {
  text-align: center;
  background: linear-gradient(
    to right,
    #46a78d 20%,
    #ffb612 40%,
    #ffb612 60%,
    #46a78d 80%
  );

  background-size: 200% auto;
  display: block;
  color: #000;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  animation: shine 3s linear infinite;
  @keyframes shine {
    to {
      background-position: 200% center;
    }
  }
}
.linear-shine-phantasia {
  text-align: center;
  background: linear-gradient(
    to right,
    #23dadb 20%,
    #5883e8 40%,
    #5883e8 60%,
    #23dadb 80%
  );

  background-size: 200% auto;
  display: block;
  color: #000;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  animation: shine 3s linear infinite;
  @keyframes shine {
    to {
      background-position: 200% center;
    }
  }
}

// CAROUSEL FOR NFT PREVIEW

.horizontal-snap {
  margin: 0 auto;
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  height: calc(180px + 1rem);
  padding: 1rem;
  max-width: 480px;
  overflow-y: auto;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
}

.horizontal-snap > div {
  scroll-snap-align: center;
}

.horizontal-snap img {
  width: 180px;
  max-width: none;
  object-fit: contain;
  border-radius: 1rem;
}

.r-20 {
  right: 20%;
}
.r-40 {
  right: 40%;
}
.r-60 {
  right: 60%;
}
.r-80 {
  right: 80%;
}
.r-100 {
  right: 100%;
}
