@import './values';

@mixin double-modifier($map, $attribute, $attribute2) {
  @each $key, $value in $map {
    &-#{$key} {
      @if type-of($value) == 'map' {
        @include double-modifier($value, $attribute, $attribute2);
      } @else {
        #{$attribute}: $value;
        #{$attribute2}: $value;
      }
    }
  }
}

@mixin modifier($map, $attribute) {
  @each $key, $value in $map {
    &-#{$key} {
      @if type-of($value) == 'map' {
        @include modifier($value, $attribute);
      } @else {
        #{$attribute}: $value;
      }
    }
  }
}

@mixin hover-modifier($map, $attribute) {
  @each $key, $value in $map {
    &-#{$key} {
      @if type-of($value) == 'map' {
        @include hover-modifier($value, $attribute);
      } @else {
        &:hover{
          transition: all .25s cubic-bezier(0, 0, .24, 1);
          #{$attribute}: $value;
        }
      }
    }
  }
}

@mixin focus-modifier($map, $attribute) {
  @each $key, $value in $map {
    &-#{$key} {
      @if type-of($value) == 'map' {
        @include focus-modifier($value, $attribute);
      } @else {
        &:focus{
          transition: all .25s cubic-bezier(0, 0, .24, 1);
          #{$attribute}: $value;
        }
      }
    }
  }
}

.group:hover .group-hover\:flex {
  display: flex
}

.group:hover .group-hover\:flex {
  display: flex
}

.group:hover .group-hover\:text {
  @include modifier($map: $color-mods, $attribute: 'color')
}

.group:hover .group-hover\:fill-danger {
  fill: var(--danger-1)
}

.group > * {
  transition: .25s cubic-bezier(0, 0, .24, 1);
}

.hover\:text {
  @include hover-modifier($map: $color-mods, $attribute: 'color')
}

.text {
  @include modifier($map: $color-mods, $attribute: 'color')
}

.hover\:bg {
  @include hover-modifier($map: $color-mods, $attribute: 'background-color')
}

.bg {
  @include modifier($map: $color-mods, $attribute: 'background-color')
}
