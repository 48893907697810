@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

body {
  width: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

body {
  background-color: var(--base-0);
}

* {
  box-sizing: border-box;
}
