$remValues: (
        0: 0px, // 0px
        1: 1px, // 1px
        2: 0.125rem, // 2px
        3: 0.25rem, // 4px
        4: 0.5rem, // 8 px
        5: 0.75rem, //12 px
        6: 1rem, // 16px
        7: 1.25rem, // 20px
        8: 1.5rem, // 24
        9: 2rem, // 32
        10: 2.5rem, // 40
        11: 3rem, // 48
        12: 3.5rem, // 56
        13: 4rem, // 64
        14: 5rem, // 80
        15: 6rem, // 96
        16: 7rem, // 112
        17: 8rem, // 128
        18: 9rem, // 144
        19: 10rem, // 160
        20: 11rem, //  176
        21: 12rem, // 192
        22: 13rem, // 208
        23: 14rem, // 224
        24: 15rem, // 240
        25: 16rem, // 256
        26: 18rem, // 288
        27: 20rem, // 320
        28: 24rem, // 384
        29: 28rem, // 448
        30: 32rem, // 512
        31: 40rem, // 640
        32: 48rem, // 768
        33: 56rem, // 896
        34: 64rem, // 1024
        35: 78rem, // 1248
        36: 92rem, // 1248
);

$negativeRemValues: (
        1: -1px,
        2: -0.125rem,
        3: -0.25rem,
        4: -0.5rem,
        5: -0.75rem,
        6: -1rem,
        7: -1.25rem,
        8: -1.5rem,
        9: -2rem,
        10: -2.5rem,
        11: -3rem,
        12: -3.5rem,
        13: -4rem,
        14: -5rem,
        15: -6rem,
        16: -7rem,
        17: -8rem,
        18: -9rem,
        19: -10rem,
        20: -11rem,
);

$percentValues: (
        '0': 0%,
        1\/2: 50%,
        1\/3: 33.33%,
        2\/3:66.666667%,
        1\/4: 25%,
        2\/4: 50%,
        3\/4: 75%,
        1\/5: 20%,
        2\/5: 40%,
        3\/5: 60%,
        4\/5: 80%,
        1\/6: 16.666667%,
        5\/6: 83.333333%,
        1\/10: 10%,
        3\/10: 30%,
        7\/10: 70%,
        9\/10: 90%,
        1\/12: 8.333333%,
        5\/12: 41.666667%,
        7\/12: 58.333333%,
        11\/12: 91.666667%,
        'full': 100%,
);

$color-mods: (
        base:(
                0: var(--base-0),
                1: var(--base-1),
                2: var(--base-2),
                3: var(--base-3),
                4: var(--base-4),
                5: var(--base-5),
        ),
        primary:(
                0: var(--primary-0),
                1: var(--primary-1),
                2: var(--primary-2),
        ),
        secondary:(
                0: var(--secondary-0),
                1: var(--secondary-1),
                2: var(--secondary-2),
        ),
        success:(
                0: var(--success-0),
                1: var(--success-1),
                2: var(--success-2),
        ),
        danger:(
                0: var(--danger-0),
                1: var(--danger-1),
                2: var(--danger-2),
        ),
        warning:(
                0: var(--warning-0),
                1: var(--warning-1),
                2: var(--warning-2),
        ),
        neutral:(
                0: var(--neutral-0),
                1: var(--neutral-1),
                2: var(--neutral-2),
        ),
);
