@import "./MaterialOverrides.scss";
@import "animations";
@import "./mixins.scss";
@import './borders.scss';
@import "./text";
@import "./fx";
@import "./layout";
@import "./sizing";
@import "./spacing";

:root {

  --base-0: #F7F8FA; // List BG
  --base-1: #fff; // Base background
  --base-2: #f4f4f5; // Buttons, inputs, etc
  --base-3: #ececee; // Borders
  --base-4: #ececee; // Extra light
  --base-5: #fff; // Raised

  --base-rgb: 255, 255, 255;
  --base-2-rgb: 244, 244, 245;

  --neutral-0: #d1d5db;
  --neutral-1: #6b7280;
  --neutral-2: #181818;

  --primary-0: #d7e2ff;
  --primary-1: #3450ff;
  --primary-2: #010128;

  --secondary-0: #cee8fc;
  --secondary-1: #236ff8;
  --secondary-2: #022031;

  --success-2: #022309;
  --success-1: #07ce74;
  --success-0: #d6ffe9;

  --danger-2: #23020a;
  --danger-1: #f1022a;
  --danger-0: #ffe2e8;

  --warning-2: #232002;
  --warning-1: #d5c50f;
  --warning-0: #fffce9;

  --ring-color: rgba(var(--neutral-rgb), .03);
  --ring-offset-width: 0px;
  --ring-offset-color: var(--base-1);
  --border-color: rgba(var(--neutral-rgb), .03);
  --border-offset-width: 0px;
  --border-offset-color: var(--base-1);

}
body {
  min-height: 100vh !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
*::-webkit-scrollbar {
  display: none;
}
.show-scrollbar::-webkit-scrollbar {
  display: flex;
  width: 8px;
}

.bx {
  align-items: center;
  display: flex;
}

/* Track */
.show-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.show-scrollbar::-webkit-scrollbar-thumb {
  background: $neutral-200;
  border-radius: 12px;
}

/* Handle on hover */
.show-scrollbar::-webkit-scrollbar-thumb:hover {
  background: $neutral-200;
}

#content-wrap {
  min-height: 100vh !important;
}

#root {
  display: flex;
  flex-direction: column;
}

button:active {
  opacity: 0.5;
}

.avoid-navbar {
  padding-top: 88px !important;
}

.footer-gradient-x {
  background: linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,.08));
}
.header-gradient-x {
  background: linear-gradient(to left, rgba(0,0,0,0), rgba(0,0,0,.08));
}
.header-gradient {
  background: linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,.08));
}
.footer-gradient {
  background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,.08));
}


.outline-primary {
  box-shadow: inset 0 0 0 1px var(--primary-1) !important;
}
.outline-success {
  box-shadow: inset 0 0 0 1px $secondary-500 !important;
}
.outline-danger {
  box-shadow: inset 0 0 0 1px $danger-500 !important;
}
.outline-warning {
  box-shadow: inset 0 0 0 1px $yellow-500 !important;
}

.outline-gray {
  box-shadow: inset 0 0 0 2px $neutral-100 !important;
}



.outline-dark {
  box-shadow: inset 0 0 0 2px $neutral-0;
}

.outline-thick {
  box-shadow: inset 0 0 0 3px var(--base-1);
}

.underline {
  box-shadow: inset 0 -2px var(--primary-1);
}
.underline-neutral {
  box-shadow: inset 0 -2px var(--base-2);
}
.underline-success {
  box-shadow: inset 0 -2px $secondary-500;
}

.outline-none {
  outline: none !important;
}
.border-none {
  border: none;
}

.transition {
  transition: all 0.2s;
}

.clickable-image:hover {
  cursor: pointer;
  opacity: 0.7;
}
.clickable-image {
  transition: all 0.2s;
}
.no-click {
  pointer-events: none;
}

.hover-up:hover {
  transform: translateY(-2px);
  cursor: pointer;
}
.hover-scale:hover {
  transform: scale(2);
}
.hover-down:hover {
  cursor: pointer;
  transform: translateY(2px);
}
.hover-right:hover {
  transform: translateX(2px);
}

.no-wrap {
  flex-wrap: nowrap;
  white-space: nowrap;
}
.wrap {
  flex-wrap: wrap;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.flex-wrap {
  flex-wrap: wrap;
}


.Probable {
  background-color: var(--success-0) !important;
  color: var(--success-2) !important;
}
.Questionable {
  background-color: var(--warning-0) !important;
  color: var(--warning-2) !important;
}
.Doubtful,
.Out {
  background-color: var(--danger-0) !important;
  color: var(--danger-2) !important;
}

.border-dashed {
  border: 2px dashed $neutral-100;
}

.hover-danger:hover {
  color: $danger !important;
}
.hover-primary:hover {
  color: $primary !important;
}
.hover-success:hover {
  color: $success !important;
}
.hover-dark:hover {
  color: var(--neutral-2) !important;
}
.hover-dark:hover > * {
  color: var(--neutral-2) !important;
}
.hover-bg-danger:hover {
  background-color: $danger-50 !important;
}
.hover-bg-primary:hover {
  background-color: $primary-50 !important;
}
.hover-bg-success:hover {
  background-color: $secondary-50 !important;
}
.hover-bg-gray:hover {
  background-color: $neutral-50 !important;
}

.expand:hover {
  transform: scale(1.05);
}

.glass {
  background-color: rgba(var(--base-rgb), .85) !important;
  backdrop-filter: blur(24px) saturate(180%);
}
.glass-2 {
  background-color: rgba(var(--base-2-rgb), 0.7) !important;
  backdrop-filter: blur(16px) saturate(180%);
}
.glass-3 {
  background-color: rgba(var(--base-2-rgb), 0.7) !important;
  backdrop-filter: blur(16px) saturate(180%);
}


.blur {
  filter: blur(20px);
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.blur-only {
  backdrop-filter: blur(60px);
}

.blur-lg {
  position: relative;
  background-color: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(120px);
}

.skew-flip {
  transform: scaleX(-1);
}
.skew-flip-y {
  transform: scaleY(-1);
}
.text-gradient-phantasia {
  background: linear-gradient(to left, #23dadb, #5883e8);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: block;
}
.text-gradient-light {
  background: linear-gradient(to right, var(--primary-1), #64f5fa);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: block;
}
.text-gradient-fire-sale {
  background: linear-gradient(to right, #f97316, #eab308);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: block;
}
.text-gradient-danger {
  background: linear-gradient(to right, $danger-300, var(--danger-1));
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: block;
}
.text-gradient-warning {
  background: linear-gradient(to right, $yellow-100, $yellow-300);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: block;
}

.text-gradient-success {
  background: linear-gradient(to right, #01b956, var(--success-1));
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: block;
}
.text-gradient {
  background: linear-gradient(to right, $primary-300, $primary-400);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text-gradient-2 {
  background: linear-gradient(to right, $primary-400, #61a8ff);
  background-clip: text;
  display: block;
  -webkit-text-fill-color: transparent;
}

.fill-primary {
  fill: var(--primary-1)
}
.fill-white {
  fill: #ffffff
}
.fill-warning {
  fill: var(--warning-1)
}
.fill-success {
  fill: var(--success-1)
}
.fill-danger {
  fill: var(--danger-1)
}
.fill-base-1 {
  fill: var(--base-1)
}
.fill-base-2 {
  fill: var(--base-2)
}
.fill-base-3 {
  fill: var(--base-3)
}
.fill-base-4 {
  fill: var(--base-4)
}
.fill-gold {
  fill: #ecc440
}

:not(:stuck) { position: sticky; /* Or fixed */ }



