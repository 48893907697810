@import './mixins';
@import './values';

.p {
  @include modifier($map: $remValues, $attribute: 'padding')
}

.-p {
  @include modifier($map: $negativeRemValues, $attribute: 'padding')
}

.px {
  @include double-modifier($map: $remValues, $attribute: 'padding-left', $attribute2: 'padding-right')
}

.-px {
  @include double-modifier($map: $negativeRemValues, $attribute: 'padding-left', $attribute2: 'padding-right')
}

.py {
  @include double-modifier($map: $remValues, $attribute: 'padding-top', $attribute2: 'padding-bottom')
}

.-py {
  @include double-modifier($map: $negativeRemValues, $attribute: 'padding-top', $attribute2: 'padding-bottom')
}

.my {
  @include double-modifier($map: $remValues, $attribute: 'margin-top', $attribute2: 'margin-bottom')
}

.-my {
  @include double-modifier($map: $negativeRemValues, $attribute: 'margin-top', $attribute2: 'margin-bottom')
}

.m {
  @include modifier($map: $remValues, $attribute: 'margin')
}

.-m {
  @include modifier($map: $negativeRemValues, $attribute: 'margin')
}

.mx {
  @include double-modifier($map: $remValues, $attribute: 'margin-left', $attribute2: 'margin-right')
}

.-mx {
  @include double-modifier($map: $negativeRemValues, $attribute: 'margin-left', $attribute2: 'margin-right')
}

.pr {
  @include modifier($map: $remValues, $attribute: 'padding-right')
}

.pl {
  @include modifier($map: $remValues, $attribute: 'padding-left')
}

.pt {
  @include modifier($map: $remValues, $attribute: 'padding-top')
}

.pb {
  @include modifier($map: $remValues, $attribute: 'padding-bottom')
}

.mr {
  @include modifier($map: $remValues, $attribute: 'margin-right')
}

.ml {
  @include modifier($map: $remValues, $attribute: 'margin-left')
}

.mt {
  @include modifier($map: $remValues, $attribute: 'margin-top')
}

.mb {
  @include modifier($map: $remValues, $attribute: 'margin-bottom')
}

.mr- {
  @include modifier($map: $negativeRemValues, $attribute: 'margin-right')
}

.ml- {
  @include modifier($map: $negativeRemValues, $attribute: 'margin-left')
}

.mt- {
  @include modifier($map: $negativeRemValues, $attribute: 'margin-top')
}

.mb- {
  @include modifier($map: $negativeRemValues, $attribute: 'margin-bottom')
}

.m-auto {
  margin: auto;
}

.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}


@each $i, $value in $remValues {
  .space-x-#{$i} > * + * {
    margin-left: #{$value}
  }
  .space-y-#{$i} > * + * {
    margin-top: #{$value}
  }

  @media (min-width: 640px) {
    .sm\:space-x-#{$i} > * + * {
      margin-left: #{$value}
    }
    .sm\:space-y-#{$i} > * + * {
      margin-top: #{$value}
    }
  }
  @media (min-width: 768px) {
    .md\:space-x-#{$i} > * + * {
      margin-left: #{$value}
    }
    .md\:space-y-#{$i} > * + * {
      margin-top: #{$value}
    }
  }

  @media (min-width: 1024px) {
    .lg\:space-x-#{$i} > * + * {
      margin-left: #{$value}
    }
    .lg\:space-y-#{$i} > * + * {
      margin-top: #{$value}
    }
  }

  @media (min-width: 1280px) {
    .xl\:space-x-#{$i} > * + * {
      margin-left: #{$value}
    }
    .xl\:space-y-#{$i} > * + * {
      margin-top: #{$value}
    }
  }
}
