@import './mixins';
@import './values';

.h {
  @include modifier($map: $remValues, $attribute: 'height')
}

.max-h {
  @include modifier($map: $remValues, $attribute: 'max-height')
}

.min-h {
  @include modifier($map: $remValues, $attribute: 'min-height')
}

.h {
  @include modifier($map: $percentValues, $attribute: 'height')
}

.w {
  @include modifier($map: $remValues, $attribute: 'width')
}

.max-w {
  @include modifier($map: $remValues, $attribute: 'max-width')
}

.min-w {
  @include modifier($map: $remValues, $attribute: 'min-width')
}

.w {
  @include modifier($map: $percentValues, $attribute: 'width')
}

.max-w {
  @include modifier($map: $percentValues, $attribute: 'max-width')
}

.min-w {
  @include modifier($map: $percentValues, $attribute: 'min-width')
}

.max-h {
  @include modifier($map: $percentValues, $attribute: 'max-height')
}

.min-h {
  @include modifier($map: $percentValues, $attribute: 'min-height')
}

.h-screen {
  height: 100vh;
}

.min-h-screen {
  min-height: 100vh;
}

.max-h-screen {
  max-height: 100vh;
}

.h-min {
  height: min-content;
}

.h-max {
  height: max-content;
}

.h-fit {
  height: fit-content;
}

.w-screen {
  width: 100vw;
}

.max-w-screen {
  max-width: 100vw;
}

.min-w-screen {
  min-width: 100vw;
}

.w-min {
  width: min-content;
}

.w-max {
  width: max-content;
}

.w-fit {
  width: fit-content;
}


