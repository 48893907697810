@import "./Colors.scss";

/** MATERIAL DESIGN OVERRIDES **/

.react-time-picker__clock {
  display: none !important;
}
.react-time-picker__inputGroup__input:focus {
  outline: none !important;
  box-shadow: inset 0 0 0 1px var(--primary-1);
  border-radius: 4px !important;
}
.react-time-picker__inputGroup__input {
  padding-left: 4px !important;
  padding-right: 4px !important;
}
.MuiPopover-paper {
  //padding: 24px !important;
  border-radius: 12px;
  box-shadow: rgba(136, 136, 136, 0.05) 0px 20px 25px -5px,
    rgba(163, 102, 102, 0.02) 0px 10px 10px -5px !important;
  overflow: visible !important;
}
.MuiPaper-elevation16 {
  background-color: var(--base-2) !important;
}
.MuiDialog-paperScrollPaper {
  padding: 0px !important;
}
.MuiPickersDay-root {
  border-radius: 50% !important;
}
.MuiPaper-root {
  border-radius: 24px !important;
  margin-top: 8px !important;
  background-color: rgba(var(--base-rgb), .9) !important;
  backdrop-filter: blur(16px) saturate(180%);
  border: 1px solid var(--ring-color) !important;
}
.MuiDialogTitle-root {
  background-color: var(--base-2);
}
.MuiTypography-root {
  font-family: $fontFamily !important;
}

.Mui-selected {
  background-color: var(--primary-1) !important;
}

.MuiDrawer-paper {
  margin-bottom: -12px !important;
  padding-bottom: 48px;
}

.modal > .MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.6) !important;
  backdrop-filter: blur(10px) !important;
}
.modal-extra-blur > .MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.7) !important;
  backdrop-filter: blur(30px) !important;
}

.PhoneInputCountry {
  background-color: var(--base-2) !important;
  padding-left: 4px;
  padding-right: 4px !important;
  border-radius: 8px;
  color: var(--neutral-2);
  position: relative;
  width: 50% !important;
  height: 44px !important;
}
.PhoneInputCountryIcon {
  display: none;
}
.PhoneInputCountrySelect {
  background-color: var(--base-2) !important;
  padding-left: 12px;
  padding-right: 4px !important;
  border-radius: 8px;
  color: var(--neutral-2);
  height: 44px !important;
  width: 100%;
  border: 0;
  display: flex;
}
.PhoneInput {
  display: flex;
  width: 100% !important;
}
.PhoneInputInput {
  display: flex;
  width: 50% !important;
}
.PhoneInputCountry:focus {
  color: var(--neutral-2);
}
.PhoneInputCountrySelect:focus {
  outline: none;
}

.wallet-adapter-button-trigger .small-trigger {
  min-height: 40px !important;
  border-radius: 50% !important;
  letter-spacing: 0 !important;
  font-size: 11px !important;
  text-transform: none !important;
}

.wallet-adapter-button-trigger  {
  width: 100%;
  min-height: 40px; padding-left: 16px !important;
  padding-right: 16px !important;
  background-color: var(--base-3) !important;
  font-family: $fontFamily;
  color: white; display: flex; align-items: center; justify-content: center;
  font-weight: 400 !important;
  border-radius: 40px !important;
  box-shadow: none !important;
  font-size: 12px !important;
  flex-wrap: nowrap !important;
  white-space: nowrap !important;
  * {
    flex-wrap: nowrap !important;
    white-space: nowrap !important;
  }
  &.Mui-disabled,
  &.contected {
    display: none;
  }
}



.MuiButton-containedPrimary {
  width: 100%;
  min-height: 48px;
  background: linear-gradient(to right, var(--primary-1), var(--secondary-1));
  font-family: $fontFamily;
  color: white;
  font-weight: 400 !important;
  border-radius: 16px !important;
  box-shadow: none !important;
  letter-spacing: 1.2px;
  * {
    flex-wrap: nowrap !important;
    white-space: nowrap !important;
  }
  text-transform: none !important;
  &.Mui-disabled,
  &.contected {
    display: none;
  }
}

.PrivateSwipeArea-root {
  height: 0px !important;
}

.wallet-ghost-button {
  box-shadow: inset 0 0 0 1px var(--base-3) !important;
  background: rgba(0, 0, 0, 0) !important;
  color: var(--neutral-2) !important;
  border-radius: 30px !important;
  max-height: 40px !important;
  height: 40px !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  min-height: 40px !important;
}

.MuiButton-iconSizeMedium > img {
  margin-right: 8px !important;
  height: 18px !important;
  width: 18px !important;
}

@media (max-width: 768px) {
  Tooltip {
    display: none !important;
  }
}

.MuiMenuItem-root {
  padding: 6px !important;
  color: var(--neutral-2) !important;
  font-size: 14px !important;
  margin: 0 !important;
  border-radius: 6px !important;
  transition: all 0.2s !important;
  min-width: 100px;
  background: var(--base-1);
}
.MuiListItem-gutters {
  padding-left: 12px;
  padding-right: 12px;
}
.MuiMenuItem-root:hover {
  transform: translateY(-1px);
}
.MuiPaper-elevation1 {
  box-shadow: rgba(136, 136, 136, 0.1) 0px 20px 25px -5px,
    rgba(163, 102, 102, 0.04) 0px 10px 10px -5px !important;
}
.MuiMenu-paper.MuiPopover-paper {
  border-radius: 30px !important;
  box-shadow: rgba(136, 136, 136, 0.05) 0px 20px 25px -5px,
    rgba(163, 102, 102, 0.02) 0px 10px 10px -5px !important;
  overflow: visible !important;
}
.MuiTooltip-tooltip {
  padding: 12px !important;
  border-radius: 12px !important;
  box-shadow: rgba(var(--neutral-rgb), 0.05) 0 12px 25px;
  overflow: visible !important;
  color: var(--neutral-2) !important;
  background-color: rgba(var(--base-rgb), .7) !important;
  backdrop-filter: blur(16px) saturate(180%);
  border: 1px solid var(--ring-color) !important;
  font-family: $fontFamily !important;
}
.MuiList-padding {
  padding: 0 !important;
}
.MuiListItem-root {
  border: none !important;
  background: var(--base-1);
}
.MuiCollapse-wrapper {
  background: var(--base-1) !important;
}
.MuiMenuItem-root {
  box-shadow: none !important;
}

.MuiListItem-gutters .MuiButton-label {
  color: var(--neutral-2);
}

.recharts-cartesian-axis-line {
  color: #000000;
}
.recharts-text {
  font-size: 13px !important;
  color: var(--nuetral-2) !important;
}
.recharts-surface,
.recharts-wrapper {
  width: 100% !important;
}
.recharts-xAxis {
  overflow: hidden !important;
  background-color: var(--nuetral-2) !important;
  z-index: 9 !important;
}

/** KYC ***************************************************/

.cqXDkq {
  background: var(--primary-1) !important;
  border: 0 !important;
  font-weight: 500 !important;
}

.zzRNh,
.bQVqjS,
.uSRYH,
.hWYbqN {
  color: black !important;
}
.igscIu,
.react-tel-input,
.form-control {
  background-color: var(--base-2) !important;
}
.igscIu.focused input.form-control:focus {
  border-color: rgba(0, 0, 0, 0) !important;
}
.igscIu.focused div.flag-dropdown {
  border-color: rgba(0, 0, 0, 0) !important;
}
