

.weight-200 {
  font-weight: 200;
}
.weight-300 {
  font-weight: 300;
}
.weight-400 {
  font-weight: 400;
}
.weight-500 {
  font-weight: 500;
}
.weight-600 {
  font-weight: 600;
}
.weight-700 {
  font-weight: 700;
}
.weight-800 {
  font-weight: 800;
}


.font-xxs {
  font-size: 8px;
}
.font-xs {
  font-size: 10px;
}
.font-sm {
  font-size: 11.5px;
}
.font-md {
  font-size: 13px;
}
.font-lg {
  font-size: 15px;
}
.font-xl {
  font-size: 20px;
}
.font-2xl {
  font-size: 24px;
}
.font-3xl {
  font-size: 32px;
}
.font-4xl {
  font-size: 40px;
}
.font-5xl {
  font-size: 48px;
}
.font-6xl {
  font-size: 64px;
}

.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.capitalize {
  text-transform: capitalize;
}

.word-spacing-lg {
  word-spacing: 3px;
}
.word-spacing-md {
  word-spacing: 2px;
}
.word-spacing-sm {
  word-spacing: 1px;
}
.word-spacing-xs {
  word-spacing: 0.5px;
}

.decoration-none {
  text-decoration: none;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-ellipsis {
  text-overflow: ellipsis;
}
.text-clip {
  text-overflow: clip;
}

.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}
.text-start {
  text-align: start;
}
.text-end {
  text-align: end;
}

.line-height-sm {
  line-height: 0.5rem;
}

.line-height-md {
  line-height: 1.25rem;
}
.line-height-lg {
  line-height: 1.5rem;
}
.line-height-xl {
  line-height: 1.75rem;
}
.letter-spacing-lg {
  letter-spacing: 3px;
}
.letter-spacing-xl {
  letter-spacing: 5px;
}
.letter-spacing-xxl {
  letter-spacing: 7px;
}
.letter-spacing-md {
  letter-spacing: 2px;
}
.letter-spacing-sm {
  letter-spacing: 1px;
}
.letter-spacing-xs {
  letter-spacing: 0.5px;
}
