@import "./base.scss";

select:not(.PhoneInputCountrySelect, .react-time-picker__inputGroup__input) {
  cursor: pointer;
  border: none;
  outline: none;
  -o-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: var(--base-2)
}

select::-ms-expand {
  display: none;
}

// Animation
@keyframes scroll {
  0% { transform: translateX(0); }
  100% { transform: translateX(-75%)}
}
// Animation
@keyframes scroll2 {
  0% { transform: translateX(0); }
  100% { transform: translateX(-93.75%)}
}

// Styling
.slider {
  //overflow:hidden;
  position: relative;
  &::before,
  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }
  &::before {
    left: 0;
    top: 0;
  }


}
.slide-track {
  animation: scroll 50s linear infinite;
  width: 400%;
  min-width: 400%
}

@media (max-width: 768px) {
  .slide-track {
    animation: scroll2 100s linear infinite;
    width: 1600%;
    min-width: 1600%
  }
}

