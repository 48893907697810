@import './mixins';
@import './values';

.rounded {
  @include modifier($map: $remValues, $attribute: 'border-radius')
}
.rounded-full {
 border-radius: 9999px;
}
.rounded-b-r {
  @include modifier($map: $remValues, $attribute: 'border-bottom-right-radius')
}
.rounded-b-l {
  @include modifier($map: $remValues, $attribute: 'border-bottom-left-radius')
}
.rounded-t-r {
  @include modifier($map: $remValues, $attribute: 'border-top-right-radius')
}
.rounded-t-l {
  @include modifier($map: $remValues, $attribute: 'border-top-left-radius')
}

.outline {
  @include modifier($map: $color-mods, $attribute: --ring-color)
}

.hover\:outline {
  @include hover-modifier($map: $color-mods, $attribute: --ring-color)
}

.focus\:outline {
  @include focus-modifier($map: $color-mods, $attribute: --ring-color)
}

.ring {
  @include modifier($map: $color-mods, $attribute: --border-color)
}

.divide-x > * + * {
  border-left: 1px solid var(--ring-color);
}

.divide-y > * + * {
  border-top: 1px solid var(--ring-color);
}

$borders: 0, 1, 2, 3, 4, 5, 6, 7, 8;

@each $i in $borders {
  .outline-#{$i} {
    --ring-shadow: inset 0 0 0 #{$i}px var(--ring-color);
    box-shadow:  var(--ring-shadow)
  }
  .outline-l-#{$i} {
    --ring-shadow: inset #{$i}px 0 0 var(--ring-color);
    box-shadow:  var(--ring-shadow)
  }
  .outline-r-#{$i} {
    --ring-shadow: inset -#{$i}px 0 0 var(--ring-color);
    box-shadow:  var(--ring-shadow)
  }
  .outline-t-#{$i} {
    --ring-shadow: inset 0 #{$i}px 0 var(--ring-color);
    box-shadow:  var(--ring-shadow)
  }
  .outline-b-#{$i} {
    --ring-shadow: inset 0 -#{$i}px 0 var(--ring-color);
    box-shadow:  var(--ring-shadow)
  }
  .hover\:outline-#{$i}:hover {
    --ring-shadow: inset 0 0 0 #{$i}px var(--ring-color);
    box-shadow: var(--ring-shadow)
  }

  .focus\:outline-#{$i}:focus {
    --ring-shadow: inset 0 0 0 #{$i}px var(--ring-color);
    box-shadow:  var(--ring-shadow)
  }

  .ring-offset-#{$i} {
    --border-offset-width: #{$i}px;
  }
  .hover\:outline-offset-#{$i}:hover {
    --border-offset-width: #{$i}px;
  }
  .focus\:ring-offset-#{$i}:focus {
    --border-offset-width: #{$i}px;
  }
  .ring-#{$i} {
    --border-shadow: 0 0 0 calc(#{$i}px + var(--border-offset-width)) var(--border-color);
    --border-offset-shadow: 0 0 0 var(--border-offset-width) var(--border-offset-color);
    box-shadow: var(--border-offset-shadow), var(--border-shadow)
  }

  .ring-l-#{$i} {
    --border-shadow: -#{$i}px 0  var(--border-color);
    box-shadow:  var(--border-shadow)
  }
  .ring-r-#{$i} {
    --border-shadow: #{$i}px 0  var(--border-color);
    box-shadow:  var(--border-shadow)
  }
  .ring-t-#{$i} {
    --border-shadow: 0 -#{$i}px var(--border-color);
    box-shadow:  var(--border-shadow)
  }
  .ring-b-#{$i} {
    --border-shadow: 0 #{$i}px var(--border-color);
    box-shadow:  var(--border-shadow)
  }
}









